import React, { useRef } from "react";
import { screenSizes } from "../styles/variables";
import { useWindowSize } from "../lib/useWindowSize";

const Scene = () => {
  const { width } = useWindowSize();
  const isPhoneScreen = width <= screenSizes.phone;
  const phoneIframeRef = useRef(null);

  return (
    <div className={"scene-container"}>
      {isPhoneScreen ? (
        <iframe
          id={"phoneIframe"}
          ref={phoneIframeRef}
          title="3d-model"
          width="100%"
          src="/assets/spaceStationMobile/210903_Newspacestation_Mobile.html"
          style={{ visibility: "hidden" }}
          onLoad={() =>
            (document.getElementById("phoneIframe").style.visibility =
              "visible")
          }
        />
      ) : (
        <iframe
          title="3d-model"
          width="100%"
          src="/assets/NEW_DESKTOP_SPACESTATION/NEW_DESKTOP_SPACESTATION.html"
        />
      )}
    </div>
  );
};

export default Scene;
